import React from 'react';
import { Layout } from '../components/Layout';
import { Link } from '../components/Link';

const Page404 = () => {
  return (
    <Layout>
      <section>
        <h1>Page not found</h1>
        <p>Oops! The page you are looking for has been removed or realocated.</p>
        <Link to="/">Go Back</Link>
      </section>
    </Layout>
  );
};

export default Page404;
